/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { phoneFormatterSeparated } from 'src/helpers/phoneFormatterHelper';

import { CustomPaper } from 'src/components/autocomplete';

export const GroupRepsInput = ({ data, value, backupRepValue, onBlur, label, name, error, placeholder, onChange = () => { } }) => {
  const [list, setList] = useState([]);

  const getValue = () => {
    return list.filter(ele => value.some(selectedItem => selectedItem.id === ele.id)) ?? '';
  };

  const handleSelect = (_, val) => {
    onChange(
      name,
      val.map(({ id }) => {
        const item = value.filter((repItem) => (repItem.id == id));

        return {
          id: id,
          // eslint-disable-next-line no-nested-ternary
          enabled: (item[0] && item[0]?.enabled == '1')
            ? 1
            : (item[0] && item[0]?.enabled == '0'
              ? 0
              : 1
            ),
        };
      }),
      false,
    );
  };

  const getGroupLabel = ({ repName, country, areaCode, phone }) => {
    return `${repName} ` + phoneFormatterSeparated(country, areaCode, phone) ?? '';
  };

  useEffect(() => {
    setTimeout(() => {
      if (backupRepValue.length > 0) {
        setList(data.filter(ele => backupRepValue.some(selectedItem => selectedItem.id !== ele.id)));
      } else {
        setList(data);
      }
    });
  }, [data, backupRepValue]);

  return (
    <Autocomplete
      options={list}
      getOptionLabel={getGroupLabel}
      disableClearable
      PaperComponent={CustomPaper}
      onChange={handleSelect}
      multiple
      renderTags={() => { }}
      filterSelectedOptions
      value={getValue()}
      renderOption={option => (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography>{getGroupLabel(option)}</Typography>
          </Grid>
        </Grid>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          size="small"
          label={label}
          variant="outlined"
          color="secondary"
          name={name}
          onBlur={onBlur}
          error={error}
        />
      )}
    />
  );
};
