import { useFormik } from 'formik';
import hexToRgba from 'hex-to-rgba';
import React, { useState, useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import * as yup from 'yup';

import { makeStyles, Typography, TextField, Grid, Button, Divider, InputAdornment, Popover, ButtonBase } from '@material-ui/core';

import Tab from 'src/components/tab';
import TooltipedText from 'src/components/tooltipedText';
import { GroupLocations as RoutingRules } from './locations/GroupLocations';
import GroupRepsList from './list/GroupReps';
import { GroupReps } from './reps/GroupReps';
import { GroupBackupRep } from './reps/GroupBackupRep';

import { useGroupActions } from '../state/hooks';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingBottom: theme.spacing(5),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  labelColor: {
    height: 20,
    width: 20,
    borderRadius: 4,
  },
  buttonSecondary: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    height: 40,
    padding: theme.spacing(1, 2)
  },
}));

export default function CreateGroup({ data, isLoading }) {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  const isShowInfo = !(data.length > 0) && !isLoading;

  const { createGroup } = useGroupActions();

  const handleExpand = () => {
    setIsExpanded(expanded => !expanded);
  };

  const formik = useFormik({
    initialValues: {
      groupName: '',
      reps: [],
      routingRules: '',
      calls_paused: 0,
      color: '#9900EF',
      backupRep: [],
    },
    validationSchema: yup.object().shape({
      backupRep:  yup.array().of(
        yup.object().shape({
          id: yup.number(),
        }),
      ),
      groupName: yup.string().required(),
      color: yup.string().required(),
      reps: yup.array().of(
        yup.object().shape({
          id: yup.number(),
          enabled: yup.number(),
        }),
      ),
      routingRules: yup.string(),
    }),
    onSubmit: (values, actions) => {
      let groupData = {
        backup_rep_id: values.backupRep.length > 0 ? values.backupRep[0].id : null,
        groupName: values.groupName,
        reps: values.reps,
        routingRules: values.routingRules,
        calls_paused: values.calls_paused,
        color: values.color,
      };

      createGroup({
        ...groupData,
        timezone_id: 4,
        call_distribution_type: 1,
        reattempts_disabled: 0,
        reattempts_timeouts: [20, 70],
        park_lead_timeout: 1,
        concurrent_calls: 3,
        working_hours: {
          mon: {
            is_open: 1,
            intervals: [{ from: '8:00', to: '18:00' }],
          },
          tue: {
            is_open: 1,
            intervals: [{ from: '8:00', to: '18:00' }],
          },
          wed: {
            is_open: 1,
            intervals: [{ from: '8:00', to: '18:00' }],
          },
          thu: {
            is_open: 1,
            intervals: [{ from: '8:00', to: '18:00' }],
          },
          fri: {
            is_open: 1,
            intervals: [{ from: '8:00', to: '18:00' }],
          },
          sat: {
            is_open: 0,
            intervals: [],
          },
          sun: {
            is_open: 0,
            intervals: [],
          },
        },
        schedule: {
          mon: {
            fresh: [{ from: '8:00', to: '18:00' }],
            reattempts: [{ from: '8:00', to: '18:00' }],
            after_hours: [{ from: '8:00', to: '18:00' }],
          },
          tue: {
            fresh: [{ from: '8:00', to: '18:00' }],
            reattempts: [{ from: '8:00', to: '18:00' }],
            after_hours: [{ from: '8:00', to: '18:00' }],
          },
          wed: {
            fresh: [{ from: '8:00', to: '18:00' }],
            reattempts: [{ from: '8:00', to: '18:00' }],
            after_hours: [{ from: '8:00', to: '18:00' }],
          },
          thu: {
            fresh: [{ from: '8:00', to: '18:00' }],
            reattempts: [{ from: '8:00', to: '18:00' }],
            after_hours: [{ from: '8:00', to: '18:00' }],
          },
          fri: {
            fresh: [{ from: '8:00', to: '18:00' }],
            reattempts: [{ from: '8:00', to: '18:00' }],
            after_hours: [{ from: '8:00', to: '18:00' }],
          },
          sat: {
            fresh: [],
            reattempts: [],
            after_hours: [],
          },
          sun: {
            fresh: [],
            reattempts: [],
            after_hours: [],
          },
        },
      });

      actions.resetForm();
      actions.setSubmitting(false);
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeColor = colorString => {
    formik.setFieldValue('color', colorString.hex, true);
  };

  const handleChangeCallsPaused = (value) => {
    formik.setFieldValue('calls_paused', value === true ? 1 : 0);
  };

  const handleUpdateRep = ({ id, ...rep }) => {
    const { reps } = formik.values;

    const repIdx = reps.findIndex(repItem => repItem.id === id);

    formik.setFieldValue(
      'reps',
      [...reps.slice(0, repIdx), { ...reps[repIdx], ...rep }, ...reps.slice(repIdx + 1)],
      false,
    );
  };

  const handleDelete = id => {
    formik.setFieldValue(
      'reps',
      formik.values.reps.filter(rep => rep.id !== id),
      false,
    );
  };

  const handleDeleteBackupRep = id => {
    formik.setFieldValue(
      'backupRep',
      formik.values.backupRep.filter(backupRep => backupRep.id !== id),
      false,
    );
  };

  useEffect(() => {
    setIsExpanded(isShowInfo);
  }, [isShowInfo]);

  return (
    <Tab
      title="Create New Group"
      onClick={handleExpand}
      callsPaused={formik.values.calls_paused === 1}
      expanded={isExpanded}
      groupId="new"
      customExpandedIcon={<Button
        size="medium"
        color="primary"
        className={classes.buttonSecondary}
      >
        {!isExpanded && <><span style={{ fontSize: 20, marginRight: 8, marginBottom: 4 }}>+</span> Add</>}
        {isExpanded && <>Minimize</>}
      </Button>}
    >
      <div className={classes.wrapper}>
        {
          isShowInfo
            ? (<Typography paragraph>
              You don’t have a ‘group’ yet. Create one now…
            </Typography>)
            : (<></>)
        }

        <Grid container alignItems="center">
          <Grid item xs={12} md={5}>
            <TextField
              label="Group name"
              variant="outlined"
              size="small"
              color="secondary"
              fullWidth
              onChange={formik.handleChange}
              name="groupName"
              value={formik.values.groupName}
              onBlur={formik.handleBlur}
              error={formik.touched.groupName && Boolean(formik.errors.groupName)}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.wrapper}>
        <Typography paragraph variant="body2">
          Reps
        </Typography>

        <Grid container alignItems="center">
          <Grid item xs="auto" md={12}>
            <TooltipedText tooltipText="To add new reps, edit or delete existing reps, click on the 'Rep' tab">
              <Grid item xs="auto" md={5}>
                <GroupReps
                  name="reps"
                  placeholder="Select rep to assign to this group"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onChange={formik.setFieldValue}
                  value={formik.values.reps}
                  backupRepValue={formik.values.backupRep}
                  onBlur={formik.handleBlur}
                  error={formik.touched.reps && Boolean(formik.errors.reps)}
                  fullWidth
                />
              </Grid>
            </TooltipedText>
          </Grid>
        </Grid>
      </div>

      {Boolean(formik.values.reps.length) && (
        <div className={classes.wrapper}>
          <Grid container alignItems="center" spacing={2}>
            <GroupRepsList
              selected={formik.values.reps}
              onUpdate={handleUpdateRep}
              onDelete={handleDelete}
            />
          </Grid>
        </div>
      )}

      <div className={classes.wrapper}>
        <Typography paragraph variant="body2">
          Backup rep
        </Typography>

        <Grid container alignItems="center">
          <Grid item xs="auto" md={12}>
            <TooltipedText tooltipText="To add new backup rep, edit or delete existing rep, click on the 'Rep' tab">
              <Grid item xs="auto" md={5}>
                <GroupBackupRep
                  name="backupRep"
                  placeholder="Select rep to assign to this group"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onChange={formik.setFieldValue}
                  value={formik.values.backupRep}
                  repsValue={formik.values.reps}
                  onBlur={formik.handleBlur}
                  error={formik.touched.backupRep && Boolean(formik.errors.backupRep)}
                  fullWidth
                />
              </Grid>
            </TooltipedText>
          </Grid>
        </Grid>
      </div>

      {Boolean(formik.values.backupRep.length) && (
        <div className={classes.wrapper}>
          <Grid container alignItems="center" spacing={2}>
            <GroupRepsList
              selected={formik.values.backupRep}
              onDelete={handleDeleteBackupRep}
              backup
            />
          </Grid>
        </div>
      )}

      <div className={classes.wrapper}>
        <Typography paragraph variant="body2">
          Routing rule
        </Typography>

        <Grid container alignItems="center">
          <Grid item xs md={5}>
            <RoutingRules
              label="Routing rules"
              name="routingRules"
              value={formik.values.routingRules}
              onChange={formik.setFieldValue}
              onBlur={formik.handleBlur}
              error={formik.touched.routingRules && Boolean(formik.errors.routingRules)}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.wrapper}>
        <Typography paragraph variant="body2">
          Color
        </Typography>

        <Grid container alignItems="center">
          <Grid item xs md={5}>
            <TextField
              size="small"
              label="Color"
              variant="outlined"
              color="secondary"
              name="color"
              // disabled
              error={formik.touched.color && Boolean(formik.errors.color)}
              onBlur={formik.handleBlur}
              value={formik.values.color?.toUpperCase()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ButtonBase
                      onClick={handleClick}
                      className={classes.labelColor}
                      style={{
                        backgroundColor: hexToRgba(formik.values.color, 0.6),
                        border: `1px solid ${formik.values.color}`,
                      }}
                    />

                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <TwitterPicker
                        triangle="hide"
                        color={formik.values.color}
                        onChange={handleChangeColor}
                      />
                    </Popover>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>

      <Divider className={classes.divider} />

      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            disabled={!formik.isValid}
            onClick={formik.handleSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Tab>
  );
}
