/* eslint-disable camelcase */
import React from 'react';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TooltipedText from 'src/components/tooltipedText';

const useStyles = makeStyles(theme => ({
  spacer: {
    marginBottom: theme.spacing(3),
  },
  spacerTop: {
    marginTop: theme.spacing(3),
  },
  parkedCalls: {
    // maxWidth: 216,
  },
  concurrentCall: {
    // maxWidth: 166
  },
  callDistribution: {
    // maxWidth: 256
  },
}));


export default function EditCallSettings({ groupId, data, onSave, onCancel }) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      concurentCalls: data.concurrent_calls,
      callDistributionType: data.call_distribution_type,
    },
    validationSchema: yup.object().shape({
      concurentCalls: yup.number().required(),
      callDistributionType: yup.string().required(),
    }),
    onSubmit: values => {
      let groupData = { ...data };
      const {
        concurentCalls: concurrent_calls,
        callDistributionType: call_distribution_type,
      } = values;

      if (call_distribution_type === '3') {
        console.log('here');
        groupData.backup_rep_id = null;
      }

      onSave({
        ...groupData,
        concurrent_calls,
        call_distribution_type,
      });
    },
  });

  return (
    <>
      <Grid container spacing={3} className={classes.spacer}>
        <Grid item md className={classes.concurrentCall}>
          <Typography variant="subtitle2" className={classes.spacer}>
            <TooltipedText tooltipText="Limits the number of calls generated at same time">
              Concurrent Calls
            </TooltipedText>
          </Typography>

          <Grid className={classes.spacerTop} container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <TextField
                name="concurentCalls"
                onChange={formik.handleChange}
                value={formik.values.concurentCalls}
                onBlur={formik.handleBlur}
                error={formik.touched.concurentCalls && Boolean(formik.errors.concurentCalls)}
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item md className={classes.callDistribution}>
          <Typography variant="subtitle2" className={classes.spacer}>
            <TooltipedText tooltipText="Choose how you want to deliver your lead calls to your Reps/Groups">
              Call Distribution Type
            </TooltipedText>
          </Typography>

          <RadioGroup
            name="callDistributionType"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.callDistributionType}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={<TooltipedText tooltipText="test">Priority ringing</TooltipedText>}
            />

            <FormControlLabel
              value="2"
              control={<Radio />}
              label="Round robin"
            />

            <FormControlLabel
              value="3"
              control={<Radio />}
              label="Simultaneous"
            />
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" spacing={3}>
        <Grid item>
          <Button color="primary" size="large" disableElevation onClick={onCancel}>
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={formik.submitForm}
            disabled={!formik.isValid}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
