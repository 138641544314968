/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { phoneFormatterSeparated } from 'src/helpers/phoneFormatterHelper';

import { CustomPaper } from 'src/components/autocomplete';

export const GroupBackupRepInput = ({ data, value, repsValue, onBlur, label, name, error, placeholder, onChange = () => { } }) => {
  const [list, setList] = useState([]);

  const getValue = () => {
    return list.filter(ele => value.some(selectedItem => selectedItem.id === ele.id)) ?? '';
  };

  const handleSelect = (_, val) => {
    onChange(
      name,
      val.map(({ id }) => {

        return {
          id: id,
        };
      }),
      false,
    );
  };

  const getGroupLabel = ({ repName, country, areaCode, phone }) => {
    return `${repName} ` + phoneFormatterSeparated(country, areaCode, phone) ?? '';
  };

  useEffect(() => {
    setTimeout(() => {
      if (repsValue.length > 0) {
        let tempList = [];

        data.forEach((dataElement) => {
          let elementInArray = false;

          repsValue.forEach((repsValueElement) => {
            if (repsValueElement.id === dataElement.id && elementInArray === false) {
              elementInArray = true;
            }
          })

          if (elementInArray === false) {
            tempList.push(dataElement);
          }
        });

        setList(tempList);
      } else {
        setList(data);
      }
    });
  }, [data, repsValue]);

  return (
    <Autocomplete
      disabled={value.length > 0}
      options={list}
      getOptionLabel={getGroupLabel}
      disableClearable
      PaperComponent={CustomPaper}
      onChange={handleSelect}
      multiple
      renderTags={() => { }}
      filterSelectedOptions
      value={getValue()}
      renderOption={option => (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography>{getGroupLabel(option)}</Typography>
          </Grid>
        </Grid>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          size="small"
          label={label}
          variant="outlined"
          color="secondary"
          name={name}
          onBlur={onBlur}
          error={error}
        />
      )}
    />
  );
};
